import Flickity from 'flickity';
const FlickitySlider = () => {

    Flickity.prototype.ontouchstart = (function(original) {
        return function(event) {
            this._pointerUp(event, event.changedTouches[0]);
            original.apply(this, arguments);
        };
    }(Flickity.prototype.ontouchstart));

    Flickity.prototype._createResizeClass = function () {
        this.element.classList.add('flickity-resize');
    };

    Flickity.createMethods.push('_createResizeClass');

    var resize = Flickity.prototype.resize;
    Flickity.prototype.resize = function () {
        this.element.classList.remove('flickity-resize');
        resize.call(this);
        this.element.classList.add('flickity-resize');
    };

    function flickitySlider(e) {
        let slider = document.querySelectorAll(
            'body.home .c-group-blocks:not(.market-blocks) .c-group-blocks__container');

        if (slider.length) {
            if (typeof slider[0].flickity === 'undefined') {
                slider[0].flickity = new Flickity(slider[0], {
                    cellSelector: '.c-group-blocks__col',
                    cellAlign: 'left',
                    contain: true,
                    draggable: '>1',
                    wrapAround: false,
                    prevNextButtons: true,
                    pageDots: true,
                    watchCSS: true,
                    adaptiveHeight: true
                });

                slider[0].flickity.on('dragStart', (event, pointer) => {
                    document.ontouchmove = e => e.preventDefault()
                });
                slider[0].flickity.on('dragEnd', (event, pointer) => {
                    document.ontouchmove = e => true
                });
            } else {
                slider[0].flickity.reloadCells()
            }
        }

        let projectSlider = document.querySelectorAll(
            'body.home .c-recent-projects__container .c-recent-projects__row.slider');

        if (projectSlider.length && projectSlider[0].querySelectorAll('.c-recent-projects__col').length) {
            if (typeof projectSlider[0].flickity === 'undefined') {
                if (projectSlider[0].querySelectorAll('.c-recent-projects__col').length <= 2) {
                    console.log('2')
                    const sliderOptions = {
                        cellSelector: '.c-recent-projects__col',
                        contain: false,
                        draggable: '>1',
                        wrapAround: false,
                        prevNextButtons: true,
                        pageDots: false,
                        watchCSS: false,
                        adaptiveHeight: true,
                        cellAlign: 'center'
                    }
                    projectSlider[0].flickity = new Flickity(projectSlider[0], sliderOptions);

                } else {
                    console.log('more')
                    const sliderOptions = {
                        cellSelector: '.c-recent-projects__col',
                        contain: true,
                        draggable: '>1',
                        wrapAround: true,
                        prevNextButtons: true,
                        pageDots: false,
                        watchCSS: false,
                        adaptiveHeight: true,
                        cellAlign: 'center'
                    }
                    projectSlider[0].flickity = new Flickity(projectSlider[0], sliderOptions);

                }

                projectSlider[0].flickity.on('dragStart', (event, pointer) => {
                    document.ontouchmove = e => e.preventDefault()
                });
                projectSlider[0].flickity.on('dragEnd', (event, pointer) => {
                    document.ontouchmove = e => true
                });

            } else {
                projectSlider[0].flickity.reloadCells()
            }

        }

        let projectModalSliders = document.querySelectorAll(
            '.modal .image-gallery.projects');

        projectModalSliders.forEach((projectModalSlider) => {
            if (projectModalSlider.querySelectorAll('.image-gallery .slide').length <= 0) {
                return;
            }

            if (typeof projectModalSlider.flickity === 'undefined') {
                projectModalSlider.flickity = new Flickity(projectModalSlider, {
                    cellSelector: '.image-gallery .slide',
                    contain: true,
                    draggable: '>1',
                    wrapAround: true,
                    prevNextButtons: true,
                    pageDots: false,
                    watchCSS: false,
                    adaptiveHeight: true
                });

                projectModalSlider.flickity.on('dragStart', (event, pointer) => {
                    document.ontouchmove = e => e.preventDefault()
                });
                projectModalSlider.flickity.on('dragEnd', (event, pointer) => {
                    document.ontouchmove = e => true
                });
            } else {
                projectModalSlider.flickity.reloadCells()
            }
        })
    }

    window.addEventListener("resize", flickitySlider);
    window.addEventListener("load", flickitySlider);

}

export default FlickitySlider
